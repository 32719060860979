<script>
  export let sectionTitle;
</script>

<style>
  hr {
    margin: 1px 30% 20px;
    border-color: whitesmoke;
  }
</style>

<div class="flex mx-5">
  <img class="" src="/images/terminal-white.svg" alt="logo for section" />
  <h2 class="text-gray-200">
    <strong>{sectionTitle}</strong>
  </h2>
</div>
<hr />
