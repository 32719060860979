<script>
  import Section from "../Components/Section.svelte";
</script>

<style>

</style>

<h1 class="text-center text-5xl sm:text-6xl font-bold py-5 text-gray-200">
  ENOS City Industries, LLC
</h1>

<Section sectionTitle={'Our business'} />
<h3 class="text-center px-3 text-gray-200">
  We design and develop SaaS (Software-as-a-Service) products for customers.
</h3>
<br />
<h3 class="text-center px-3 text-gray-200">
  Our current project is an app to enhance the job seeking experience. Coming
  soon!
</h3>
<br />
<br />

<Section sectionTitle={'Contact'} />
<h3 class="text-center px-3 text-gray-200">
  Please reach out with any questions or suggestions,
  <a href="mailto:contact@eci.gg" target="_blank">right here.</a>
</h3>
<br />
<h3 class="text-center text-gray-200">©2020 ENOS City Industries, LLC</h3>
